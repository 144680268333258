import { ref, useRoute } from '@nuxtjs/composition-api';

import { useApi } from '~/composables';
import { usePreviewMode } from '~/diptyqueTheme/composable';
import type {
  GetCategoryUrlByIdResponse,
  GetProductBySkuResponse,
  UseContentfulPreviewInterface
} from '~/diptyqueTheme/composable/useContentfulPreview/types';
import getCategoryUrlById from '~/diptyqueTheme/customQueries/magento/getCategoryUrlById';
import getProductUrlBySku from '~/diptyqueTheme/customQueries/magento/getProductUrlBySku';
import { Logger } from '~/helpers/logger';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';

export function useContentfulPreview(): UseContentfulPreviewInterface {
  const route = useRoute();
  const { preview: previewIndex, cids: categoryIds, skus: productSKUs } = route.value.query;
  const { query } = useApi();
  const { isPreviewMode } = usePreviewMode();

  const categoryStore = useCategoryStore();

  const categoryURL = ref<string>();
  const productURL = ref<string>();

  const getPreviewIndex = (previewIndex: string | string[], array: string[]): number => {
    let parsedIndex = Number(previewIndex);

    if (isNaN(parsedIndex) || typeof parsedIndex !== 'number') {
      parsedIndex = 1;
    }

    let adjustedIndex = parsedIndex - 1;

    if (adjustedIndex < 0) {
      adjustedIndex = 0;
    } else if (adjustedIndex >= array.length) {
      Logger.debug('getPreviewIndex error: previewIndex is out of bounds. previewIndex:', previewIndex, 'array length:', array.length);
      adjustedIndex = array.length - 1;
    }

    return adjustedIndex;
  };

  const getCategoryLink = async (): Promise<void> => {
    if (!categoryIds) return;

    const idsArray = (categoryIds as string).split(',');
    const categoryIndex = getPreviewIndex(previewIndex, idsArray);
    try {
      const { data, errors }: GetCategoryUrlByIdResponse = await query(getCategoryUrlById, {
        filters: { ids: { in: idsArray[categoryIndex] } },
        is_preview: isPreviewMode.value
      });

      if (!data) {
        throw new Error(`Received an empty response for category ${idsArray[categoryIndex]}`);
      }

      if (errors?.length) {
        throw new Error(errors[0].message || 'Unknown error');
      }

      const categoryItems = data?.categories?.items || [];
      if (categoryItems.length > 0) {
        const { url_path } = categoryItems[0];
        categoryURL.value = `l/${url_path}.html`;
        categoryStore.setClearUrl(url_path);
      }
    } catch (e) {
      Logger.error('getCategoryLink error', e);
    }
  };

  const getProductLink = async (): Promise<void> => {
    if (!productSKUs) return;

    const skusArray = (productSKUs as string).split(',');
    const productIndex = getPreviewIndex(previewIndex, skusArray);
    try {
      const { data, errors }: GetProductBySkuResponse = await query(getProductUrlBySku, {
        filter: { sku: { eq: skusArray[productIndex] } },
        is_preview: isPreviewMode.value
      });

      if (errors?.length) {
        throw new Error(errors[0].message || 'Unknown error');
      }

      if (!data?.products?.items?.length) {
        Logger.info('getProductLink: no products received with sku ', skusArray[productIndex]);
        return;
      }

      const productItem = data.products.items[0];
      const productUrlRewrites = productItem?.url_rewrites || [];

      if (productUrlRewrites.length > 0) {
        const { url } = productUrlRewrites[0];
        productURL.value = url;
      }

      if (!productURL.value && productItem?.url_key) {
        productURL.value = `p/${productItem.url_key}.html`;
      }
    } catch (e) {
      Logger.error('getProductLink error', e);
    }
  };

  return {
    getCategoryLink,
    getProductLink,
    categoryURL,
    productURL
  };
}
