import { useContext, useRouter } from '@nuxtjs/composition-api';

import productGetters from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import type { UseCartAddItemEngravingParams } from '~/modules/core/composables/useCart';
// eslint-disable-next-line no-duplicate-imports
import { useCart } from '~/modules/core/composables/useCart';

export const useAddToCart = () => {
  const { addItem: addItemToCartBase, isInCart, loading } = useCart();
  const router = useRouter();
  const { app } = useContext();

  const addItemToCart = async (params: { product: Product; quantity: number }, engravingCartItems?: UseCartAddItemEngravingParams) => {
    const { product, quantity } = params;

    const productType = product.__typename;

    switch (productType) {
      case 'SimpleProduct':
      case 'LinkedProductInterface':
      case 'SimpleCartItem': {
        await addItemToCartBase(
          {
            product,
            quantity
          },
          engravingCartItems
        );

        break;
      }
      case 'BundleProduct':
      case 'ConfigurableProduct':
      case 'GroupedProduct': {
        const sku = productGetters.getProductSku(product);
        const slug = productGetters.getSlug(product).replace(/^\//, ''); // remove leading slash from getSlug

        const path = app.localeRoute({
          name: 'product',
          params: {
            id: sku,
            slug
          }
        });

        await router.push(path);
        break;
      }
      default:
        throw new Error(`Product Type ${productType} not supported in add to cart yet`);
    }
  };

  return {
    addItemToCart,
    isInCart,
    loading
  };
};
