import Vue from 'vue';

Vue.directive('trim', {
  bind(el) {
    const input = el.tagName === 'INPUT' ? el : el.querySelector('input');

    if (input) {
      input.addEventListener('blur', () => {
        const trimmedValue = typeof input.value === 'string' ? input.value.trim() : input.value;

        const isEmailField = input.type === 'email';
        if (trimmedValue !== input.value || isEmailField) {
          input.value = trimmedValue;
          input.dispatchEvent(new Event('input'));
        }
      });
    }
  }
});
