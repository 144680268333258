import { ref, useContext, watch } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';

interface NostoState {
  productId: string;
  categoryId: string;
  clientScriptLoaded: boolean;
}

export const useNostoStore = defineStore('nosto', () => {
  const { app, $config } = useContext();

  const state = ref<NostoState>({
    categoryId: null,
    productId: null,
    clientScriptLoaded: false
  });

  const setProductId = (id: string) => (state.value.productId = id);
  const setCategoryId = (id: string) => (state.value.categoryId = id);
  const setClientScriptLoaded = () => (state.value.clientScriptLoaded = true);

  const getProductId = () => state.value.productId;
  const getCategoryId = () => state.value.categoryId;
  const getClientScriptLoaded = () => state.value.clientScriptLoaded;

  const getAccountId = () => {
    const localeCode = app.$vsf.$magento.config.state.getLocale();

    return $config.nosto[localeCode] || $config.nosto['en_eu'];
  };

  return {
    state,
    setProductId,
    setCategoryId,
    getProductId,
    getCategoryId,
    getAccountId,
    getClientScriptLoaded,
    setClientScriptLoaded
  };
});
