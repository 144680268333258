/** GraphQL Mutation that adds external products to the cart */
export default `
  mutation addToGuestCartFromRemote( $cartItems: [CartItemInput!]!, $cartMask: String!) {
    addToGuestCartFromRemote(cart_items: $cartItems, cart_mask: $cartMask) {
      cart {
        id
        email
        total_quantity
        freeshipping_threshold_info {
          freeshipping_enabled
          freeshipping_left
          currency_symbol
          freeshipping_amount
        }
        items {
          id
          uid
          product {
            id
            uid
            __typename
            sku
            name
            stock_status
            thumbnail {
              url
            }
          }
          quantity
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;
