import type { ActionResponse, API as NostoAPI } from '@nosto/types';

import { useNostoStore } from '~/diptyqueTheme/stores/nosto';
import { Logger } from '~/helpers/logger';

declare global {
  interface Window {
    nostojs?: (cb: (api: NostoAPI) => void) => unknown;
  }
}

/**
 * Executes a provided callback with the Nosto API and logs the result of `load`.
 * @param cb - Callback function using the Nosto API.
 * @param key - A descriptive key for logging purposes.
 */
export function useNostoApi(cb: (api: NostoAPI) => Promise<ActionResponse | void | unknown>, key: string) {
  const store = useNostoStore();
  const nostoCb = async (api) => {
    try {
      const res = await cb(api);
      Logger.debug(`[NOSTO] [${key}]:`, res);
    } catch (error) {
      Logger.error(`[NOSTO] [${key}] Error during API load:`, error);
    }
  };

  if (store.$state.state.clientScriptLoaded) {
    window.nostojs(nostoCb);
  } else {
    store.$subscribe((mutation, state) => {
      if (state.state.clientScriptLoaded) {
        window.nostojs(nostoCb);
      }
    });
  }
}
