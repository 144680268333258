




import type { PropType } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { defineComponent } from '@nuxtjs/composition-api';

import { useNostoSearch } from '~/diptyqueTheme/composable/nosto/useNostoSearch';

export default defineComponent({
  name: 'NostoSearch',
  props: {
    query: {
      type: String as PropType<string>,
      default: ''
    }
  },
  setup(props) {
    useNostoSearch(props.query);
  }
});
